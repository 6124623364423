.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-content-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 0 2% 1% 5%;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* z-index: 1; */
  font-size: 1.5rem;
}
.fixed-content-footer__copy {
}
.social-links__anchor:hover {
  /* background: #4295cfd9;
  transition: background 300ms ease; */
}
.social-links__anchor:nth-of-type(1) {
  transition-delay: 600ms;
  transition-property: transform, opacity;
}
.social-links__anchor:nth-of-type(2) {
  transition-delay: 650ms;
  transition-property: transform, opacity;
}
.social-links__anchor:nth-of-type(3) {
  transition-delay: 700ms;
  transition-property: transform, opacity;
}
.social-links__anchor:nth-of-type(4) {
  transition-delay: 750ms;
  transition-property: transform, opacity;
}
.social-links__anchor:nth-of-type(5) {
  transition-delay: 800ms;
  transition-property: transform, opacity;
}
.social-links__anchor:nth-of-type(6) {
  transition-delay: 850ms;
  transition-property: transform, opacity;
}

.social-links__anchor {
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  text-decoration: none;
}
.social-links__anchor:last-of-type {
  margin-right: 0;
}

.social-links__image {
  filter: invert(90%);
  vertical-align: bottom;
  cursor: pointer;
}
.social-links__image:hover {
  filter: invert(100%);
}

body {
  width: 100%;
  height: 100vh;
  background: #0b0024;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: #ffffff00;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  color: #000;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  /* transform: scale(-1); */
}

.next img,
.prev img {
  width: 80%;
}

img.slider-image {
  position: absolute;
  max-width: 100%;
  width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
